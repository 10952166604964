<template>
  <ul class="nav nav-pills chart-pills">
    <li class="nav-item">
      <a class="nav-link text-capitalize" href="#"
         :class="{'active': !selectedIndustry, 'disabled': selectedPicaServices.length === 0}"
         @click.prevent="selectIndustry()">
        <translate>All</translate>
        ({{ selectedPicaServices.length|number }})</a>
    </li>
    <li class="nav-item" v-for="(item, index) in industries" :key="index">
      <a class="nav-link text-capitalize" href="#"
         :class="{'active': selectedIndustry===item && selectedPicaServices.filter(x => x.industry === item).length > 0,
                               'disabled': selectedPicaServices.filter(x => x.industry === item).length === 0}"
         @click.prevent="selectIndustry(item)">
        {{ getIndustryConfig(item).label }}
        ({{ selectedPicaServices.filter(x => x.industry === item).length | number }})</a>
    </li>
  </ul>
</template>
<script>
import {getIndustryConfig} from "@/views_pica/stats/constants";

export default {
  name: "IndustriesPills",
  emits: ['select'],
  data() {
    return {
      selectedIndustry: null
    }
  },
  props: {
    industries: {
      type: Array,
      required: true
    },
    selectedPicaServices: {
      type: Array,
      required: true
    }
  },
  methods: {
    getIndustryConfig,
    selectIndustry(industry) {
      this.selectedIndustry = industry;
      this.$emit('select', industry);
    },
  }
}
</script>


<style scoped>

</style>