<template>
  <div class="stats-main-container">
    <ViewLoadingMessage :loading="loading"/>
    <template v-if="!loading">
      <CRow class="mb-4 justify-content-lg-between">
        <CCol lg="12" class="mb-4">
          <h1 class="main-header mb-1" v-translate>Analytics</h1>
        </CCol>
        <CCol sm="12" xl="auto" class="mb-4">
          <div class="d-flex flex-column flex-lg-row align-items-start">
            <DatePickerTimeZone
                @input="onDatePickerInput"
                :timeZoneValue="timeZonePickerValue"
                :extraTimezones="customTimezoneList()"
                :showDatePicker="showDatePicker"
                :showTimeZonePicker="showTimeZonePicker"
                :showTimeZoneText="!showTimeZonePicker"
                :shortcut="datePickerShortcut"/>
          </div>
        </CCol>

        <CCol sm="auto" xl="auto" v-if="isMultiEvents">
          <IndustriesPills :selected-pica-services="selectedPicaServices"
                           :industries="industries"
                           v-if="picaServices"
                           @select="selectIndustry"/>
        </CCol>

        <CCol sm="auto" xl="auto" class="text-right">
          <EventsSelector v-if="isMultiEvents"
                          :filter-industry="selectedIndustry"
                          @on-data="applyEventsSelection"
                          @on-apply="applyEventsSelection"/>
        </CCol>
      </CRow>

      <!-- Stats highlight -->
      <CRow class="mb-4">
        <CCol>
          <CRow>
            <CCol sm="6" xl v-if="canViewStatsOrdersTransacted" style="min-height: 110px;">
              <OrdersKpi :is-resort="isResort"
                         :is-activation="isActivation"
                         :is-multi-events="isMultiEvents"/>
            </CCol>
            <CCol sm="6" xl v-if="canViewStatsCheckins" style="min-height: 110px;">
              <CheckinsKpi :is-activation="isActivation"
                           :is-multi-events="isMultiEvents"/>
            </CCol>
            <CCol sm="6" xl v-if="isEngagementEnabled">
              <EventBasicKpi />
            </CCol>

            <CCol sm="6" xl v-if="showPhotographicProduction"
                  style="min-height: 110px;">
              <PhotographicProductionKpi/>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <OrdersCard ref="ordersCard"
                  :industry="selectedIndustry"
                  :pica-service-id="picaServiceId"
                  :show-releases="showReleases"
                  :is-multi-events="isMultiEvents"
                  :is-activation="isActivation"
                  :is-promo-event="isPromoEvent"
                  :is-endurance="isEndurance"
                  :period-enabled="periodEnabled"
                  :is-dataset-too-older="isDatasetTooOlder"
                  :get-params="getParams"
                  :get-body-params="getBodyParams"/>

      <CheckinsCard ref="checkinsCard"
                    :industry="selectedIndustry"
                    :pica-service-id="picaServiceId"
                    :is-multi-events="isMultiEvents"
                    :is-activation="isActivation"
                    :period-enabled="periodEnabled"
                    :is-dataset-too-older="isDatasetTooOlder"
                    :get-params="getParams"
                    :get-body-params="getBodyParams"/>

      <MetricCard :date_to="selectedDatesForMetrics().to"
                  :date_from="selectedDatesForMetrics().from"
                  :need_date="isResort || isMultiEvents"
                  :time_zone="timeZone"
                  v-if="isEngagementEnabled" />

      <PhotographicProductionCard ref="photographicProductionCard"
                                  :is-multi-events="isMultiEvents"
                                  :pica-service-id="picaServiceId"
                                  :get-params="getParams"
                                  :get-body-params="getBodyParams"
                                  :current-period="period.DAY"
                                  :show-imago-data="showImagoData"
                                  :show-photographic-production-table="showPhotographicProductionTable"
                                  @canView="showPhotographicProduction=true" v-if="!isMultiEvents" />

    </template>
  </div>

</template>

<script>
import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import moment from "moment";
import {
  industriesConfig, industriesKeys,
} from "@/views_pica/stats/constants";
import EventsSelector from "@/components/EventsSelector.vue";
import DatePickerTimeZone from "@/components/DatePickerTimeZone.vue";
import {mapActions, mapGetters} from "vuex";
import {getPercentage} from "@/utils/functions";
import {thirdPartyTool} from "@/constants";
import EventBasicKpi from "@/domain/eventMetric/components/EventBasicKPI.vue";
import {period} from "@/domain/eventMetric/consts";
import {EUROPE_ROME} from "@/utils/date";
import OrdersKpi from "@/views_pica/stats/orders/OrdersKpi.vue";
import CheckinsKpi from "@/views_pica/stats/checkins/CheckinsKpi.vue";
import PhotographicProductionKpi from "@/views_pica/stats/photographicProduction/PhotographicProductionKpi.vue";
import OrdersCard from "@/views_pica/stats/orders/OrdersCard.vue";
import CheckinsCard from "@/views_pica/stats/checkins/CheckinsCard.vue";
import PhotographicProductionCard from "@/views_pica/stats/photographicProduction/PhotographicProductionCard.vue";
import IndustriesPills from "@/views_pica/stats/components/IndustriesPills.vue";
import MetricCard from "@/views_pica/stats/metrics/MetricCard.vue";

export default {
  name: 'StatsNew',
  components: {
    MetricCard,
    IndustriesPills,
    PhotographicProductionCard,
    CheckinsCard,
    OrdersCard,
    PhotographicProductionKpi,
    CheckinsKpi,
    OrdersKpi,
    EventBasicKpi,
    DatePickerTimeZone,
    EventsSelector,
    ViewLoadingMessage,
  },
  props: {
    isMultiEvents: {
      type: Boolean, required: false, default: false
    }
  },
  data() {
    return {
      firstLoad: true,
      loading: true,
      picaServiceId: null,

      picaServices: [],
      selectedIndustry: null,
      showPhotographicProduction: false,

      datePickerShortcut: null,
      timeZonePickerValue: null,
      timeZone: null,
      fromDate: null,
      toDate: null,
      from: null,
      to: null
    }
  },
  computed: {
    ...mapGetters('stats', [
      'eventCurrency',
      'canViewStatsOrdersTransacted',
      'canViewStatsCheckins'
    ]),
    ...mapGetters('eventMetric', {
      metricsConfiguration: 'configuration'
    }),
    ...mapGetters('event', [
      'industry',
      'isPromo',
      'thirdPartyTool',
    ]),
    ...mapGetters('event', {
      eventTimeZone: 'timeZone'
    }),

    showDatePicker() {
      return this.isMultiEvents || this.isResort
    },
    showTimeZonePicker() {
      return this.hasPerm('dashboard.view_timezone_select')
    },

    showReleases() {
      return this.isEndurance
    },
    isEndurance() {
      return !this.isMultiEvents && this.industry === industriesKeys.ENDURANCE
    },
    isResort() {
      return !this.isMultiEvents && this.industry === industriesKeys.RESORT
    },
    isCorporate() {
      return !this.isMultiEvents && this.industry === industriesKeys.CORPORATE
    },
    isPromoEvent() {
      return !this.isMultiEvents && this.isPromo
    },
    isStadium() {
      return !this.isMultiEvents && this.industry === industriesKeys.STADIUM
    },
    isActivation() {
      return !this.isMultiEvents && (this.isStadium || this.isCorporate)
    },
    showImagoData() {
      return !this.isMultiEvents && this.thirdPartyTool === thirdPartyTool.IMAGO
    },
    showPhotographicProductionTable() {
      return !this.isMultiEvents && !this.isStadium && !this.isCorporate
    },
    period() {
      return period
    },

    industries() {
      let industries = []
      for (const event of this.picaServices) {
        if (!industries.find(x => x === event.industry)) industries.push(event.industry)
      }

      const industriesConfigToArray = Object.keys(industriesConfig)
      industries.sort(function (a, b) {
        return industriesConfigToArray.indexOf(a) - industriesConfigToArray.indexOf(b);
      });
      return industries
    },
    selectedPicaServices() {
      return this.picaServices.filter(x => x.checked === true)
    },
    selectedPicaServicesByIndustry() {
      if (this.selectedIndustry) {
        return this.selectedPicaServices.filter(picaService => picaService.industry === this.selectedIndustry)
      } else {
        return this.selectedPicaServices
      }
    },
    periodEnabled() {
      return !this.from && !this.to
    },

    isEngagementEnabled() {
      if (!this.canSeeEngagement) return false
      return this.metricsConfiguration !== null
    },
    canSeeEngagement() {
      if (this.isMultiEvents) return false
      return this.hasPerm('dashboard.view_feature_stats_metrics')
    },
    isDatasetTooOlder() {
      let dates = this.selectedPicaServicesByIndustry.map(event => event.event_start_at)
      dates.sort((a, b) => Date.parse(b) - Date.parse(a))
      dates.reverse()
      const olderEventsDate = new Date(dates[0])
      const today = new Date()
      return (today - olderEventsDate) / (1000 * 3600 * 24 * 365) > 1
    }
  },

  async mounted() {
    this.setInitialDates()
    if (this.isMultiEvents) {
      this.resetTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    } else {
      this.picaServiceId = this.$store.getters['event/picaServiceId']
      this.resetTimeZone(this.eventTimeZone || EUROPE_ROME)
      this.selectedIndustry = this.industry
    }
    if (this.canSeeEngagement) {
      this.setMetricsEventId(this.picaServiceId)
      await this.fetchMetricConfiguration()
    }
    this.loading = false
  },
  watch: {
    customTimezoneList: function () {
      if (!this.firstLoad) this.getAllData()
    },
  },
  methods: {
    setInitialDates() {
      if (this.isMultiEvents) {
        const m = moment()
        this.from = m.startOf("isoWeek").format("YYYY-MM-DD");
        this.fromDate = m.startOf("isoWeek").toDate()
        this.to = m.endOf("isoWeek").format("YYYY-MM-DD");
        this.toDate = m.endOf("isoWeek").toDate()
        this.datePickerShortcut = "thisWeek"
      }
    },
    resetTimeZone(value) {
      this.timeZonePickerValue = value
      this.timeZone = value
    },
    onDatePickerInput(value) {
      this.timeZone = value.timezone
      this.from = value.start
      this.to = value.end
      this.fromDate = value.startDate
      this.toDate = value.endDate
      this.getAllData()
    },
    selectedDatesForMetrics() {
      let from, to;
      if (this.fromDate) {
        from = this.fromDate
      } else {
        from = moment('2000-01-01T00:00:00').toDate()
      }
      if (this.toDate) {
        to = this.toDate
      } else {
        to = moment().endOf('day').toDate()
      }
      return {from, to}
    },
    customTimezoneList() {
      if (this.isMultiEvents) {
        return this.picaServices.map(event => event.timezone || EUROPE_ROME)
      }
      return [this.eventTimeZone || EUROPE_ROME]
    },
    getPercentage,
    ...mapActions('eventMetric', {
      fetchMetricConfiguration: 'fetchConfiguration',
      setMetricsEventId: 'setEventId'
    }),
    ...mapActions('stats', [
      'cleanupStats',
    ]),
    applyEventsSelection(picaServices) {
      this.picaServices = picaServices
      this.getAllData()
    },

    selectIndustry(industry) {
      this.selectedIndustry = industry
      this.getAllData()
    },

    getAllData() {
      this.firstLoad = false

      this.cleanupStats()

      this.$refs.ordersCard.getData()
      this.$refs.checkinsCard.getData()

      this.$refs.photographicProductionCard.getData()
    },

    getParams(countBy) {
      return {
        count_by: countBy ? countBy : this.period.DAY.suffix,
        from: this.from,
        to: this.to,
        tz: this.timeZone,
      }
    },
    getBodyParams() {
      if (!this.isMultiEvents) return {}
      return {
        pica_service_ids: this.selectedPicaServicesByIndustry.map(event => event.id)
      }
    }
  },
  beforeDestroy() {
    this.cleanupStats()
  }
}
</script>

